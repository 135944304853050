import { Accessor, createEffect, createSignal, onMount, Show } from "solid-js";
import { CardGiftV3 } from "./brand_collection_components";
import { VoucherProductStatus } from "~/server/types/search";
import { showSnackBar } from "~/shared_states/snackbar";

enum CardPosition {
  Initial = "initial",
  Intermediate = "intermediate",
  Final = "final",
}

export type GiftBrandListingData = {
  heroImageUrl?: string;
  id: string;
  plainLogoUrl: string;
  title: string;
  cardBackgroundColor?: string;
  status: VoucherProductStatus;
};

export default function CardsMultiBrand(props: {
  onClickBrand: (id: string) => void;
  brands: GiftBrandListingData[];
  animate: boolean;
  showCards: Accessor<boolean>;
}) {
  const [cardPosition, setCardPosition] = createSignal<CardPosition>(
    props.animate ? CardPosition.Initial : CardPosition.Final
  );

  const [showRemainingCards, setShowRemainingCards] = createSignal(
    props.animate ? false : true
  );

  const [animateCard1, setAnimateCard1] = createSignal(
    props.animate ? false : true
  );
  const [animateCard2, setAnimateCard2] = createSignal(
    props.animate ? false : true
  );
  const [animateCard3, setAnimateCard3] = createSignal(
    props.animate ? false : true
  );

  createEffect(() => {
    if (props.showCards()) {
      if (props.animate) {
        startAnimation();
      }
    }
  });

  async function startAnimation() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    setCardPosition(CardPosition.Intermediate);
    setAnimateCard1(true);
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateCard2(true);
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateCard3(true);
    await new Promise((resolve) => setTimeout(resolve, 700));
    setCardPosition(CardPosition.Final);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setShowRemainingCards(true);
  }

  function animateCardByIndex(index: number) {
    switch (index) {
      case 0:
        return animateCard1();
      case 1:
        return animateCard2();
      case 2:
        return animateCard3();
      default:
        return false;
    }
  }

  // sort inactives to the end
  props.brands.sort((a, b) => {
    if (a.status == "INACTIVE") {
      return 1;
    }
    if (b.status == "INACTIVE") {
      return -1;
    }
    return 0;
  });

  return (
    <div
      class="flex w-full flex-col flex-wrap justify-center gap-5 transition-all duration-[300ms]"
      classList={{
        "translate-y-[40px]": cardPosition() !== CardPosition.Final,
        "translate-y-0": cardPosition() === CardPosition.Final,
      }}
    >
      {props.brands.map((brand, index) => (
        <div
          onClick={() => {
            if (brand.status == "ACTIVE") {
              props.onClickBrand(brand.id);
            } else {
              showSnackBar({
                message: "Brand is unavailable. Try again later",
                level: "info",
              });
            }
          }}
          class=" dark relative w-full "
          classList={{
            ...getCardAppearanceProps({
              index: index,
            }),
            "transition-all ": props.animate,
            "duration-[600ms]  ": cardPosition() === CardPosition.Final,
            "duration-[300ms] ease-[cubic-bezier(.06,1.25,.37,1.11)]":
              cardPosition() === CardPosition.Intermediate,
          }}
        >
          <CardGiftV3
            heroImageUrl={brand.heroImageUrl}
            plainLogoUrl={brand.plainLogoUrl}
            title={brand.title}
            cardBackgroundColor={brand.cardBackgroundColor}
            borderRadius={15}
          />
          <Show when={brand.status == "INACTIVE"}>
            <div class="absolute top-0 h-full w-full rounded-2xl bg-black opacity-50 "></div>
            <div class=" absolute right-3 top-3 w-min rounded-full bg-white px-[10px] py-1 text-f12 font-semibold text-baseSecondaryDark opacity-100">
              Unavailable
            </div>
          </Show>
        </div>
      ))}
    </div>
  );

  function getCardAppearanceProps(props: {
    index: number;
  }): Record<string, boolean> {
    if (props.index < 3) {
      return getAnimatedCardAppearanceProps();
    }
    return getStaticCardAppearanceProps();

    function getAnimatedCardAppearanceProps(): Record<string, boolean> {
      return {
        [getCardsIntermediatePosition(props.index)]:
          cardPosition() === CardPosition.Intermediate &&
          animateCardByIndex(props.index),
        [getCardsInitialPosition(props.index)]:
          cardPosition() !== CardPosition.Final,
        "scale-0": !animateCardByIndex(props.index),
        "opacity-0": cardPosition() !== CardPosition.Final,
        "opacity-100 scale-100":
          cardPosition() !== CardPosition.Initial &&
          animateCardByIndex(props.index),
        "rotate-[0deg] translate-y-[0px]":
          cardPosition() === CardPosition.Final,
      };
    }

    function getStaticCardAppearanceProps(): Record<string, boolean> {
      return {
        "opacity-0": !showRemainingCards(),
        "opacity-100": showRemainingCards(),
      };
    }
  }

  function getCardsIntermediatePosition(index: number) {
    switch (index) {
      case 0:
        return "rotate-[-13deg] translate-y-[0px] ";
      case 1:
        return "rotate-[12deg] translate-y-[-110%] ";
      case 2:
        return "rotate-[2deg] translate-y-[-220%] ";
      default:
        return "";
    }
  }

  function getCardsInitialPosition(index: number) {
    switch (index) {
      case 0:
        return "translate-y-[0px]  "; //rotate-[-10deg]
      case 1:
        return "translate-y-[-105%]  "; // rotate-[10deg]
      case 2:
        return "translate-y-[-210%]  "; // rotate-[0deg]
      default:
        return "";
    }
  }
}
